/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { AbstractObjectList } from "src/app/model/abstract-object";
import { User } from "src/app/model/user";
import { BatchType, ImportParser } from '../import-page/Import-parser-interface';
import { ImportDoc } from "../ImportDoc";
import { Unit } from "src/app/model/unit";
import { ImportRow } from "../ImportRow";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { MyInjector } from "src/app/app.module";
import { HttpClient } from "@angular/common/http";

import { BCodeService } from "../../budget/bcode.service";
import { BCode } from "src/app/model/bcode";
import { HeaderField, ImportField } from "../ImportField";
import { ImportInput } from "../ImportInput";
import { PicklistOptions } from "src/app/shared/field/PicklistField";
import { required } from "src/app/shared/validators";
import { Field } from "src/app/shared/field/Field";
import { FormPicklistComponent } from "src/app/shared/form/form-picklist/form-picklist.component";
import { FormButtonComponent } from "src/app/shared/form/form-button/form-button.component";
import { MatDialog } from "@angular/material/dialog";
import { BCodePageComponent } from "../../budget/b-code-page/b-code-page.component";
import { FormComboBoxComponent } from "src/app/shared/form/form-combo-box/form-combo-box.component";

@Injectable({
    providedIn: 'root'
})
export class BMTrialBalanceParser implements ImportParser {

    batchType: BatchType = 'TrialBalance';

    bCodeSvc = inject(BCodeService);
    cds = inject(ConfirmDialogService);
    dialog = inject(MatDialog);

    bCodes : BCode[] = [];

    loaded = false;

    units: Unit[] = [];

    importDoc: ImportDoc;
    currentUser: User;

    setUp() {
        this.importDoc = new ImportDoc();
        if (this.loaded) {
            this.cds.alert('You need to reload the page', 'Page reload needed', () => {
                window.location.reload();
            })
        }
        this.loaded = true;
        return this.bCodeSvc.get<BCode>().pipe(map(bcodes => {
            console.log(bcodes);
            this.bCodes = bcodes.slice();
            return true;
        }));
        /*
        return forkJoin({
            currentUser: this.currentUserSvc.getCurrentUser().pipe(first()),
            types: this.typeSvc.get<UnitType>(true),
            cats: this.catSvc.get(true),
            areas: this.areaSvc.get<Area>(true),
            units: this.unitSvc.get(true),
            schedules: this.scheduleSvc.get<Schedule>(false),
        }).pipe(map(result => {
            this.currentUser = result.currentUser;
            this.unitTypePOB.setOptions(result.types, this.importDoc);
            this.unitTypePOB.setCreator( UnitTypePageComponent );
            this.unitAreaPOB.setOptions(result.areas, this.importDoc);
            this.unitAreaPOB.setCreator( AreaPageComponent );
            this.unitCorePOB.setOptions(this.getCores(result.areas), this.importDoc)
            this.unitCategoryPOB.setOptions(result.cats, this.importDoc);
            this.units = result.units as Unit[];
            this.setupSchedules(result.schedules);
            this.loaded = true;
            return true;
        }));
        */
    }

    parseRows(rows: unknown[][]): ImportDoc {
        console.warn('Parsing rows', { rows });
        this.importDoc = new ImportDoc();
        const headerRow = this.getHeaderFields().parse(rows[0]);
        console.log(headerRow);
        if (headerRow.fullErrorCount() === 0) {
            this.parseLines(rows);
        } else {
            console.log('Unparseable header');
            this.importDoc.addNote('Header not parseable - Sure this is a BM Trial Balance Export?');
            this.importDoc.add(headerRow);
        }
        console.warn(this.importDoc);

        return this.importDoc;
    }
    parseLines(rows: unknown[][]) {
        for (let i = 2; i < rows.length - 4; i++) {
            const row = this.parseLine(rows[i]);
            if (row.fullErrorCount() === 0) {
                this.setType(row);
                this.setBCode(row, i);
            }
            this.importDoc.add(row);
        }
        console.warn(this.importDoc);
        return this.importDoc;
    }

    setType(row: ImportRow) {
        const type = row.getStringValue('Type');
        const typeField = new ImportField('typeId');
        if (type === 'Asset') {
            typeField.setValue(1);
        } else if (type === 'Liability') {
            typeField.setValue(2);
        } else if(type === 'Capital') {
            typeField.setValue(3);
        } else if (type === 'Income') {
            typeField.setValue(4);
        } else if (type === 'Expense') {
            typeField.setValue(5);
        } else {
            const typeIn = row.getField('Type');
            typeIn.addError('Type not recognised');
        }
        row.add(typeField);
    }

    setBCode(row: ImportRow, idx: number) {
        const bcField = new ImportField('Budget Code');

        const bcode = this.matchBCode(row);
        if (bcode) {
            bcField.setValue(bcode.id);
        } else {
            row.addError('Budget Code not matched');
            row.add(bcField);
            this.addBCodeInput(row, idx);
        }
    }

    addBCodeInput(row, idx: number) {
        console.log('bcodeinput', row);
        const desc = row.getStringValue('Account Name') + ' (' + row.getStringValue('Code') + ')';
        const name = `BCode${idx}`;
        const input = new ImportInput(name);
        input.setHelp($localize`Select the Budget Code for ${desc}`);
        const plRefresher = (o: BCode) => {
            //this.optionMap.set(optionValue, o);
            //this.noteMapping(optionValue, o, newInput);
            console.log(o);
        }
        const plOptions = {
            items: this.bCodes, refreshes: [plRefresher],
            optionDisplayValue: (o: BCode) => o.name + ' (' + o.sort + ')',
        } as Partial<PicklistOptions>;
        const fldOptions = {
            validators: [required], valueChanges: (value) => {
                console.log(value);
                this.importDoc.removeInput(input);
            }
        } as Partial<Field>;

        const label = $localize`'Budget Code to import '${desc}' as`;
        const field = FormComboBoxComponent.make(label, 'value', null, plOptions, fldOptions);
        input.addField(field);
        this.createOptionAdderInput(desc, plRefresher, input);

        this.importDoc.addInput(input);

    }

    createOptionAdderInput(optionValue: string, plRefresher, input: ImportInput) {

        const btn = FormButtonComponent.makeTextButton('Create New', 'optionValue', ( () => {
                this.creatorFn(optionValue).subscribe( newItem => {
                    if (newItem) {
                        //this.options.push(newItem); refresher adds the new one it seems...
                        console.log(newItem);
                        plRefresher(newItem);
                        this.importDoc.removeInput(input);
                    }
                });
            }).bind(this)
        );
        btn.makeControl().setValue('Or Create New');
        input.addField(btn);
    }

    creatorFn(optionValue: string) {
        const dialogRef = this.dialog.open(BCodePageComponent,
            {
                data:
                {
                    name: optionValue,
                    hideTabs: true,
                    mode: 'new'
                }
            }
        );
        return dialogRef.afterClosed();
    }

    matchBCode(row: ImportRow) {
        console.log(row);
        return null;
    }

    validateData(postItems: Unit[]) {
        const dataRows = this.importDoc.getGeneralRows();
        for (let i = 0; i < postItems.length; i++) {
            const line = dataRows[i];
            console.log('Validate ', line);
            /*
            const data = postItems[i] as Unit;
            if (data.coreId && data.areaId) {
                const area = this.unitAreaPOB.getOption<Area>(data.areaId);
                console.log({data, area});
                if (!area.cores.find( c => c.areaId === area.id)) {
                    line.addError('The core does not belong to the area specified');
                }
            } else if (data.coreId && !data.areaId) {
                line.addError('Area is mandatory when core is specified');
            }
            if (data.typeId && data.categoryId) {
                const unitType = this.unitTypePOB.getOption<UnitType>(data.typeId);
                if (!unitType.categories.find( c => c.typeId === unitType.id)) {
                    line.addError('The category does not belong to the specified type');
                }
            } else if (data.categoryId && !data.typeId) {
                line.addError('Type is mandatory when category is specified');
            }
            */
        }
        this.importDoc.finalise();
    }

    postToServer(): Observable<AbstractObjectList> {

        console.log('All Validated?');

        if (this.importDoc.hasErrors()) {
            return throwError(new Error('Validation Errors'));
        }

        const msg = "Import Trial Balance Information?";
        const choice = {
            title: 'Confirm Import',
            msg,
            options: [{ name: 'OK', returns: true }]
        }

        const postData = {
            type: this.batchType,
            fields: ['Code', 'Type', 'Account Name', 'Debit', 'Credit', 'Budget'],
            items: []
        }

        return this.cds.openChoice(choice).pipe(concatMap(proceed => {
            if (proceed) {
                const url = AbstractHttpService.ajaxPath + 'importFile/' + this.batchType;
                const http = MyInjector.instance.get(HttpClient);
                return http.post<AbstractObjectList>(url, postData);
            } else {
                this.cds.openConfirm("Operation Cancelled", "No data will be imported");
            }
        }));
    }

    getHeaderFields() {
        const row = new ImportRow();

        row.add(new HeaderField('Expense Category'));
        row.add(new HeaderField('Code'));
        row.add(new HeaderField('Type'));
        row.add(new HeaderField('Account Name'));
        row.add(new HeaderField('Debit'));
        row.add(new HeaderField('Credit'));
        row.add(new HeaderField('Budget'));

        return row;
    }

    parseLine(line: unknown[]) {
        const row = this.getDetailFields().parse(line);
        return row;
    }

    getDetailFields() {
        const row = new ImportRow();

        row.add(new ImportField('Expense Category'));
        row.add(new ImportField('Code'));
        row.add(new ImportField('Type'));
        row.add(new ImportField('Account Name'));
        row.add(new ImportField('Debit').asNumber());
        row.add(new ImportField('Credit').asNumber());
        row.add(new ImportField('Budget').asNumber());

        return row;
    }
}
