/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BankAccount } from 'src/app/model/bankAccount';
import { BCode } from 'src/app/model/bcode';
import { Period } from 'src/app/model/period';
import { Schedule } from 'src/app/model/schedule';
import { Unit } from 'src/app/model/unit';
import { User } from 'src/app/model/user';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { TxnImportChecker } from '../../txn/txn.service';
import { BM5ParserService } from '../BlockMan/BM5ParserService';
/*
import { BMOmcParserService } from '../BlockMan/BMOMCParser';
import { BMOmcProcessorService } from '../BlockMan/BMOMCProcessor';
import { BMSupplierParserService } from '../BlockMan/BMSupplierParser';
import { BMSupplierProcessorService } from '../BlockMan/BMSupplierProcessor';
import { RequestProcessorService } from '../General/RequestProcessor';
import { RequestParserService } from '../General/RequestParserService';
import { BMEmergencyParserService } from '../BlockMan/BMEmergencyParser';
*/
import { BM5ProcessorService } from '../BlockMan/BM5ProcessorService';
import { BMPurchaseInvoiceParserService } from '../BlockMan/BMPurchaseInvoiceParserService';
import { BMPurchaseInvoiceProcessorService } from '../BlockMan/BMPurchaseInvoiceProcessorService';
import { ImportDoc } from '../ImportDoc';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { CurrentUserService } from '../../user/current-user.service';
import { BM4ParserService } from '../BlockMan/BM4Parser';
import { BM2ParserService } from '../BlockMan/BM2Parser';
import { BM1ParserService } from '../BlockMan/BM1Parser';

import { ByteCountPipe } from '../../../shared/pipes/byte-count.pipe';
import { UploadProgressComponent } from '../../../shared/form/file/upload-progress/upload-progress.component';
import { UploadDragAndDropDirective } from '../../../shared/form/file/file-upload/upload-drag-and-drop.directive';
import { CtlHolderComponent } from '../../../shared/form/ctl-holder/ctl-holder.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgTemplateOutlet } from '@angular/common';
import { MyInjector } from 'src/app/app.module';
import { UnitParserService } from '../General/UnitParserService';
import { CSVImporter } from './csv-importer';
import { PeopleParserService } from '../General/PeopleParserService';
import { ServiceChargeParserService } from '../General/ServiceChargeParserService';
import { ImportOption } from './ImportOption';
import { MessageService } from 'src/app/shared/message.service';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { AbstractObject } from 'src/app/model/abstract-object';
import { BMTrialBalanceParser } from '../BlockMan/BMTrialBalanceParser';

export class ImportFile extends AbstractObject {
    result: string;
    status: string;
}

@Component({
    selector: 'app-import-page',
    templateUrl: './import-page.component.html',
    styleUrls: ['./import-page.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, MatButtonModule, MatIconModule, CtlHolderComponent, UploadDragAndDropDirective,
        UploadProgressComponent, ByteCountPipe, MatIconModule]
})
export class ImportPageComponent {

    static readonly navRoute = new NavRoute('imports', ImportPageComponent, 'publish');

    options: ImportOption[] = [];
    currentOption: ImportOption = null;
    parsing = false;

    importFiles: ImportFile[] = [];

    importerField = FormPicklistComponent.make('Import Type', 'importer', '', {
        refreshes: [this.refreshCurrentOption.bind(this)]
    });
    importerControl = this.importerField.makeControl();

    files: { progress: number, name: string, size: number, failed: boolean }[];

    importDoc: ImportDoc = new ImportDoc();
    isVeryNarrow = false;
    units: Unit[];
    currentUser: User;

    schedules: Schedule[];
    bCodes: BCode[];
    bankAccounts: BankAccount[];
    periods: Period[];
    existingData: TxnImportChecker;

    working = false;
    validated = false;

    ready = false;

    showRowDetails = 100;

    constructor(
        narrowSvc: IsNarrowService,
        private cds: ConfirmDialogService,
        private currentUserSvc: CurrentUserService,
        private msgSvc: MessageService,
        private http: HttpClient,
        BM5ParserSvc: BM5ParserService,
        BM5ProcessorSvc: BM5ProcessorService,
        bmPIParserSvc: BMPurchaseInvoiceParserService,
        bmPIProcessorSvc: BMPurchaseInvoiceProcessorService,
        /* bmOmcParser: BMOmcParserService,
        bmOmcProcessor: BMOmcProcessorService,
        bmSupplierParser: BMSupplierParserService,
        bmSupplierProcessor: BMSupplierProcessorService, */
        bm1Parser: BM1ParserService,
        bm2Parser: BM2ParserService,
        bm4Parser: BM4ParserService,
        /* bmEmergencyParser: BMEmergencyParserService,
        requestParser: RequestParserService,
        requestProcessor: RequestProcessorService, */
    ) {

        this.currentUserSvc.getCurrentUser().subscribe(user => {
            if (this.currentUser && this.currentUser.current_team_id !== user.current_team_id) {
                window.alert('You have changed team process cannot continue ');
                window.location.reload();
            }
            this.currentUser = user;
        });

        if (this.currentUser.inAgency) {
            this.options = [
                //{ id: 101, name: 'Request Imports', parser: requestParser, processor: requestProcessor },
                //{ id: 511, name: 'Blockman OMCs Import', parser: bmOmcParser, processor: bmOmcProcessor },
                //{ id: 512, name: 'Blockman Supplier (Creditor) Import', parser: bmSupplierParser, processor: bmSupplierProcessor },
                //{ id: 513, name: 'Blockman Emergency Contact Import', parser: bmEmergencyParser, processor: null }
            ];
        } else {
            this.options = [
                {
                    id: 0, name: 'Unit Import',
                    parser: MyInjector.instance.get(UnitParserService),
                    processor: null,
                    sampleFile: './assets/sample-files/units.csv'
                },
                {
                    id: 1, name: 'People Import', parser: MyInjector.instance.get(PeopleParserService), processor: null,
                    sampleFile: './assets/sample-files/people.csv'
                },
                { id: 5, name: 'Service Charge / Credit Import', parser: MyInjector.instance.get(ServiceChargeParserService), processor: null },
                { id: 501, name: 'Blockman 1 Unit Data', parser: bm1Parser, processor: null },
                { id: 502, name: 'Blockman 2 Unit Percentages Import', parser: bm2Parser, processor: null },
                { id: 503, name: 'Blockman 4 Unit Data Import', parser: bm4Parser, processor: null, delimiter: '|' },
                // { id: 504, name: 'Blockman BM5 Transaction Import', parser: BM5ParserSvc, processor: BM5ProcessorSvc, delimiter: '|' },
                { id: 510, name: 'Blockman Trial Balance Imports', parser: MyInjector.instance.get(BMTrialBalanceParser), processor: null, },
                { id: 524, name: 'Blockman BM5 Transaction Import', parser: BM5ParserSvc, processor: BM5ProcessorSvc },
                //{ id: 505, name: 'Blockman Creditors Details Listing CSV Import', parser: bmPIParserSvc, processor: bmPIProcessorSvc },
            ];
        }

        this.importerField.picklist.items = this.options;

        narrowSvc.detectVeryNarrow().subscribe(result => { this.isVeryNarrow = result; });
    }

    refreshCurrentOption(o: ImportOption = this.currentOption) {
        this.currentOption = o;
        this.importFiles = [];
        if (this.currentOption.parser.batchType) {
            const url = AbstractHttpService.ajaxPath + 'importFile/' + this.currentOption.parser.batchType;
            this.http.get<ImportFile[]>(url).subscribe(result => this.importFiles = result);
        }
    }
    /**
   * handle file dropped on control
   */
    onFileDropped($event) {
        this.uploadFiles($event);
    }

    /**
     * handle file user selected from file System browsing
     */
    fileBrowseHandler(files) {
        this.uploadFiles(files);
    }

    uploadFiles($event) {

        const upFile = $event[0];
        this.parsing = true;
        this.currentOption.parser.setUp().subscribe(
            () => {
                const reader = new FileReader();
                reader.onload = this.parseFile.bind(this);
                reader.readAsText(upFile);
                setTimeout(() => this.parsing = false, 100);
            }
        );
    }

    selectAgain() {
        console.log('Resetting The Import Document');
        this.importDoc = new ImportDoc();
    }

    parseFile(pe: ProgressEvent) {
        try {
            console.log('Parsing input file');
            const csv = (pe.target as FileReader).result;
            const rows = (new CSVImporter()).csvToArray(csv, (this.currentOption.delimiter ? this.currentOption.delimiter : ','));
            console.log(rows);
            this.importDoc = this.currentOption.parser.parseRows(rows);
            this.importDoc.finalise();
        } catch (e) {
            this.cds.alert('Fatal Error', 'Could not parse file', this.selectAgain.bind(this));
            console.error(e);
        }

        //const parser = new BM5Parser();
        //this.importDoc = parser.parseRows(rows, this.units, this.schedules, this.bCodes, this.existingData);
    }

    postData() {
        this.currentOption.processor.postData();
    }

    showValidateButton() {
        if (!this.importDoc.hasErrors()
            && this.importDoc.getRows().length > 0
            && !this.importDoc.needsFix()
            && !this.validated) {
            let theReturn = true;
            this.importDoc.getInputs().forEach(ii => {
                ii.getControls().forEach(ic => {
                    if (!ic.valid) {
                        theReturn = false;
                    }
                })
            })
            return theReturn;
        } else {
            return false;
        }
    }

    validateData() {
        let errors = 0;
        for (const ii of this.importDoc.getInputs()) {
            for (const f of ii.getFields()) {
                if (f.control.valid) {
                    console.log('OK');
                } else {
                    console.log('Error');
                    f.control.markAsDirty();
                    f.control.markAsTouched();
                    errors += 1;
                }
            }
        }

        if (errors === 0) {
            if (this.currentOption.parser.postToServer) {
                this.postOnParser();
            } else {
                this.postOnProcessor();
            }
        }
    }

    postOnParser() {
        this.working = true;
        this.currentOption.parser.postToServer().subscribe(success => {
            //const cnt = success.itemList.length
            console.warn(success);
            //const msg = $localize `You have successfully imported ${cnt} records`;
            this.importDoc.clearInputs();
            this.importDoc.setRows([]);
            this.importDoc.completed = success.itemList?.length;
            if (this.currentOption.parser.batchType) {
                this.msgSvc.show('Import request successfully submitted');
                this.refreshCurrentOption();
            }
            this.working = false;
        }, failure => {
            console.error('Import failed', failure);
            const msg = failure?.message ? failure.message : '';
            this.cds.open('Cannot Import File', `File cannot be imported. ${msg}. Try loading another?`, () => {
                this.selectAgain();
            })
        });
    }
    postOnProcessor() {
        this.currentOption.processor.setUp().subscribe(() => {
            console.log('Setup complete, pre processing data');
            this.currentOption.processor.getData(this.importDoc);
            this.currentUserSvc.httpGetCurrentUser(); // Force a refresh so we can see we are in right team...
            this.importDoc.finalise();
            console.log('Done');
        });
        this.working = false;
        this.validated = true;
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.importDoc.getRows().length > 0) {
            return confirm('Do you wish to abandon the import?');
        }
        return true;
    }
}
