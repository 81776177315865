<h1 style="margin: 20px 20px 0px 20px">Billing Details</h1>
<div class="main">
    <div>
        <ng-container [ngTemplateOutlet]="billingTemplate"></ng-container>
    </div>
    <div style="padding-left: 20px; flex: 1">
        <ng-container [ngTemplateOutlet]="billingStatusTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="billingItemsTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="CommunicationsTemplate"></ng-container>
    </div>
</div>

<ng-template #billingTemplate>
        <mat-card style="padding:20px; margin-bottom:20px">
            <p class="label">Billing Cycle / Budget</p>
            <p><a [routerLink]="budgetNav">{{billing?.cycle?.name}}</a></p>
            <p class="label">Cycle Started</p>
            <p>{{billing?.cycle?.from | date}}</p>
            <p class="label" style="margin-bottom: 3px">Billing Status</p>
            <p> <span [ngClass]="billing.statusId === 9 ? 'Complete' : 'Working'">{{billing?.status?.name}}</span></p>
            @if (billing.statusId === 9) {
            <p class="label" style="margin-bottom: 3px">Collection Status</p>
            <p><span [class]="collectionStatus">{{pctAchieved | percent}}</span>
                ({{pctExpected | percent}} expected)</p>
            <app-currency-item label="Billed" [amount]="billing.billedTotal"></app-currency-item>
            <app-navigate-item label="Charges Raised" value="{{billing.totalItems }} Items Invoiced" [navRoute]="invNavRoute"
                [navParms]="{ billingId: billing.id }"></app-navigate-item>
            <app-currency-item label="Outstanding" [amount]="billing.outstandingTotal"></app-currency-item>
            <app-navigate-item label="Items Outstanding" value="{{billing.outstandingItems}} Items still outstanding"
                [navRoute]="invNavRoute" [navParms]="{ billingId: billing.id, outstanding: '>=0.01' }"></app-navigate-item>
            }
            <app-text-edit label="Name" [textValue]="billing.name"></app-text-edit>
            <app-text-edit label="Notes" [textValue]="billing.notes"></app-text-edit>
            <app-date-time-item label="Created" [date]="billing.createdAt"></app-date-time-item>
            <app-date-time-item label="Updated" [date]="billing.updatedAt"></app-date-time-item>
        </mat-card>
</ng-template>
<ng-template #billingItemsTemplate>
    <mat-card style="padding: 20px">
        <h2>Billing Items</h2>
        <div style="display:flex; align-items: center">
            <table>
                <tr>
                    <th>Schedule</th><th>Budget Code</th><th>Total</th><th>Billed</th>
                    @for (u of units; track $index) {
                        <th>{{u.name}}</th>
                    }
                </tr>
                @for (bi of billing.items; track $index) {
                    <tr>
                        <td><a [routerLink]="getScheduleUrl(bi.scheduleId)">{{bi.schedule.name}}</a></td>
                        <td> <a [routerLink]="getBCodeUrl(bi.bCodeId)">{{bi.bCodeName}}</a></td>
                        <td class="number">{{bi.total | currency}}</td>
                        <td class="number">{{bi.billed | currency}}</td>
                        @for (u of units; track $index) {
                            <td class="number">{{bi['unit-' + u.id] | currency}}</td>
                        }
                    </tr>
                }
                <tr style="font-weight:bold">
                    <td></td><td  class="number">Totals</td>
                    <td  class="number">{{billing['toBill'] | currency}}</td>
                    <td  class="number">{{billing['wasBilled'] | currency}}</td>
                    @for (u of units; track $index) {
                        <td class="number">{{billing['total-' + u.id] | currency}}</td>
                    }
                </tr>
            </table>
            <div style="margin-left: 10px">
                <button mat-mini-fab (click)="showUnit()" matTooltip="Click to show the charges to be billed for an example unit"><mat-icon>add</mat-icon></button>
            </div>
        </div>
    </mat-card>
</ng-template>

<ng-template #CommunicationsTemplate>
    <mat-card style="padding: 20px; margin-top:20px">
        <h2>Communications</h2>
        <table>
            <tr>
                <th>Name</th>
                <th></th>
                <th>Min Paid</th>
                <th>Max Paid</th>
                <th>Target</th>
                <th>To Send</th>
                <th matTooltip="Total invoices processed">D</th>
                <th matTooltip="Units Emailed">U</th>
                <th matTooltip="People Emailed">P</th>
                <th matTooltip="Invoices in PDF">D</th>
                <th>Destination</th>
                <th>Status</th>
                <th colspan="2">Created</th>
                <th colspan="2">Updated</th>
            </tr>
            @for (mm of billing.mailMerges; track $index) {
                <ng-container [ngTemplateOutlet]="CommunicationItemTemplate"
                    [ngTemplateOutletContext]="{mm}"></ng-container>
            }
        </table>
        <p style="text-align:center; margin-top: 20px">
            <button mat-mini-fab (click)="addCommunication()" matTooltip="Click to send charges to unit owners"><mat-icon>add</mat-icon></button>
        </p>
    </mat-card>
</ng-template>

<ng-template #CommunicationItemTemplate let-mm="mm">
    <tr>
        <td>{{mm.name}}</td>
        <td>
            @if(mm.status === 'New') {
                <button mat-raised-button color="primary" style="height: 24px" (click)="addCommunication(mm)">
                    Edit
                </button>
            }
        </td>
        <td class="number">{{mm.params.minPaid | percent}}</td>
        <td class="number">{{mm.params.maxPaid | percent}}</td>
        <td>{{mm.params['target']}}</td>
        <td class="number">{{mm.todo}}</td>
        <td class="number">
            @if (mm.done) {
                {{mm.done}}
            }
        </td>
        <td class="number">
            @if (mm.unitEmails) {
                <a [routerLink]="getUnitsLink(mm)">{{mm.unitEmails}}</a>
            }
        </td>
        <td class="number">
            @if (mm.emails) {
                <a [routerLink]="getPeopleLink(mm)">{{mm.emails}}</a>
            }
        </td>
        <td class="number">
            @if(mm.unitPDFs) {
                {{mm.unitPDFs}}
            }
        </td>
        <td>{{mm.destination}}</td>
        <td><span [class]="mm.status">{{mm.status}}</span></td>
        <td><app-user-name [id]="mm.createdById"></app-user-name></td>
        <td [matTooltip]="mm.createdAt * 1000 | date:'long'">{{mm.createdAt | fromNow}}</td>
        @if (mm.updatedAt) {
            <td><app-user-name [id]="mm.updatedById"></app-user-name></td>
            <td [matTooltip]="mm.updatedAt * 1000 | date:'long'">{{mm.updatedAt | fromNow}}</td>
        } @else {
            <td></td><td></td>
        }
    </tr>
    @if (mm['attachField']) {
        <tr>
            <td colspan="100" style="text-align: center">
                <app-ctl-holder [control]="mm['attachField'].control"></app-ctl-holder>
            </td>
        </tr>
    }
</ng-template>

<ng-template #billingStatusTemplate>
    @if (toBeApproved) {
        <mat-card style="padding: 20px; margin-bottom: 20px;  text-align:center">
            <p>This billing is proposed, it has not yet been approved by the members.</p>
            <p>If it is now approved, you need to go to the
                <a [routerLink]="budgetNav">Budgeting Page</a>
                and mark it as approved.</p>
        </mat-card>
    }
    @if (toBeBilled) {
        <mat-card style="padding: 20px; margin-bottom: 20px; text-align:center">
            <p>This billing is approved, but charges have not yet been generated</p>
            <p>
                <button mat-raised-button color="primary" style="width:20em" (click)="billOut()">Generate Charges</button>
            </p>
        </mat-card>
    }
    @if (beingBilled) {
        <mat-card style="padding: 20px; margin-bottom: 20px; text-align:center">
            <p>This billing is queued to be generated, please check back later</p>
        </mat-card>
    }
</ng-template>
